.catalog_swiper {
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 10px; }
  .catalog_swiper .swiper-pagination-progressbar {
    position: absolute;
    bottom: 0px;
    top: unset;
    background: transparent; }
    .catalog_swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: #000; }

.catalog_title {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%; }

.product_card__block {
  cursor: pointer; }
  .product_card__block:hover .item_like {
    top: 15px; }
  .product_card__block:hover .product_card_content {
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px); }

.product_card_top {
  overflow: hidden;
  position: relative;
  height: 380px; }

.product_card_content {
  transition: all .2s;
  position: absolute;
  width: 100%;
  padding: 6px;
  bottom: -100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 99;
  justify-content: space-between;
  align-items: center; }

.product_card_size {
  transition: all .2s;
  margin: 0px 7px; }
  .product_card_size__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px; }
  .product_card_size_active {
    font-size: 16px; }

.product_card_color {
  position: relative;
  transition: all .2s;
  margin: 7px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid transparent; }
  .product_card_color__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .product_card_color::before {
    content: '';
    border: 1px solid transparent;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px; }
  .product_card_color_active::before {
    border: 1px solid #000; }

.product_card_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.product_card_title {
  margin-top: 10px;
  margin-bottom: 7px; }

.product_card_img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%; }
  .product_card_img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.product_card_hint_new {
  background: #CF1A20;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  z-index: 1; }

.product_card_hint_sale {
  background: #000000;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  z-index: 1; }

.item_like {
  transition: all .2s;
  position: absolute;
  top: -50px;
  right: 15px;
  z-index: 99;
  right: 15px;
  width: 30px;
  height: 30px; }

.liked {
  background: red;
  border-radius: 50%; }
