.btn_standart {
  transition: all .2s;
  min-width: 170px;
  display: block;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  padding: 14.5px 10px; }
  .btn_standart_red {
    background: linear-gradient(180deg, #CF1A20 0%, #EE2A26 100%);
    box-shadow: 0px 10px 10px rgba(115, 202, 194, 0.5);
    border: solid 1px transparent !important; }
    .btn_standart_red:hover {
      border: solid 1px transparent !important;
      background: linear-gradient(180deg, #a81419 0%, #c2211e 100%); }
  .btn_standart_white {
    background: #fff;
    box-shadow: 0px 10px 10px rgba(115, 202, 194, 0.5);
    border: solid 1px transparent !important; }
    .btn_standart_white:hover {
      border: solid 1px #bdd2d0 !important;
      background: #bdd2d0;
      color: #fff; }
