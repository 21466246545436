.footer_main {
  padding-top: 100px; }
  @media (max-width: 1300px) {
    .footer_main {
      padding: 30px 50px; } }
  @media (max-width: 1200px) {
    .footer_main {
      padding: 10px 30px; }
      .footer_main .footer_top_contacts__title {
        display: none; } }
  @media (max-width: 992px) {
    .footer_main {
      display: none; } }
  .footer_main .footer_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #F2F2F2; }
    .footer_main .footer_top .footer_top_contacts {
      display: flex;
      justify-content: space-around;
      max-width: 600px;
      width: 100%;
      align-items: center;
      padding-right: 0; }
      .footer_main .footer_top .footer_top_contacts .footer_top_contacts__numbers {
        display: flex;
        flex-direction: column; }
        .footer_main .footer_top .footer_top_contacts .footer_top_contacts__numbers a {
          margin-bottom: 8px; }
    .footer_main .footer_top .header_sociate__block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-width: 150px;
      margin: 0; }
      .footer_main .footer_top .header_sociate__block .footer_top_contacts__title {
        margin-right: 40px; }
  .footer_main .footer_midlle {
    display: flex;
    margin-top: 80px; }
    @media (max-width: 1300px) {
      .footer_main .footer_midlle {
        margin-top: 40px; }
        .footer_main .footer_midlle_other-info {
          margin-top: 30px; } }
    .footer_main .footer_midlle_other-info {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1300px) {
        .footer_main .footer_midlle_other-info {
          margin-top: 30px; } }
      .footer_main .footer_midlle_other-info a {
        margin-right: 25px; }
    .footer_main .footer_midlle_info {
      width: 70%; }
      .footer_main .footer_midlle_info_content {
        display: flex;
        flex-wrap: wrap; }
        .footer_main .footer_midlle_info_content a {
          width: 25%;
          margin: 10px 0; }
    .footer_main .footer_midlle_form {
      display: flex;
      align-items: center;
      width: 30%; }
      .footer_main .footer_midlle_form__title {
        margin-bottom: 46px; }

.form_email {
  display: flex; }
  .form_email_input {
    max-width: 400px;
    width: 100%; }
    .form_email_input:hover {
      border: 1px solid red;
      border-radius: 2px; }

.content_flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.footer_mobile {
  display: none; }
  @media (max-width: 992px) {
    .footer_mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .footer_mobile__logo {
    margin: 30px 0; }
  .footer_mobile_midlle {
    width: 100%; }
    .footer_mobile_midlle .content_column {
      display: block;
      column-count: 2;
      overflow: hidden; }
      .footer_mobile_midlle .content_column a {
        display: flex;
        margin: 10px 0; }
    .footer_mobile_midlle .content_margin a {
      margin: 0; }
  .footer_mobile_other_info {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .footer_mobile__development {
    display: flex;
    justify-content: center;
    align-items: center; }
    .footer_mobile__development p {
      margin-right: 10px; }

.header_nav_content__block > a {
  cursor: pointer; }
