.category_main .category__block {
  margin: 20px; }

.category_main .category_item {
  margin-right: 20px; }

.category_index .category__block {
  height: 535px; }

.category_index .category_item {
  margin: 5px; }

.category__block {
  height: 360px;
  display: flex;
  margin: 0 5px; }

.category_item {
  position: relative;
  height: 100%;
  cursor: pointer;
  overflow: hidden; }
  .category_item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .category_item__wrapper .category_item {
    width: calc(100% - 10px);
    height: 262px;
    margin-bottom: 10px; }
  .category_item_33procent {
    width: calc(33.3% - 10px); }
  .category_item_50procent {
    width: calc(50% - 10px); }
  .category_item_66procent {
    width: calc(66.6% - 10px); }
  .category_item:hover .category_content, .category_item:hover:after {
    top: 0; }
  .category_item:after {
    transition: all .2s;
    z-index: 0;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.612); }

.category_content {
  z-index: 1;
  transition: all .2s;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 40px;
  top: 110%;
  left: 0; }
  .category_content_link {
    transition: all .2s;
    padding: 20px 10px;
    height: fit-content;
    width: fit-content;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
    transform: scale(0.9); }
    .category_content_link:hover {
      transform: scale(1);
      border-bottom: 1px solid #CF1A20; }

.category_hint {
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 1300px) {
  .category_content {
    padding: 10px 20px; } }

@media (max-width: 992px) {
  .category_index .category__block {
    flex-direction: column;
    height: auto; }
  .category_item__wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
    .category_item__wrapper .category_item {
      margin-bottom: 5px; }
  .category_content {
    display: none; }
  .category_item {
    width: 100%;
    height: 282px; }
    .category_item:after {
      display: none; }
    .category_item:hover .category_content, .category_item:hover:after {
      top: 120%; } }

@media (max-width: 500px) {
  .category_item img {
    padding-bottom: 60px; }
  .category_hint {
    right: unset !important;
    left: 0 !important;
    width: 100%; } }

@media (max-width: 400px) {
  .category_item__wrapper {
    display: flex;
    flex-direction: column; }
    .category_item__wrapper .category_item {
      width: 100%; } }
